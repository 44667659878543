import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a className="sel" href="/">
                    Hlavná stránka
                  </a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="page_content">
            <div className="wysiwyg uc ac">
              <h1>POTREBUJETE RÝCHLU ÚĽAVU OD NÁDCHY?</h1>
              <p>NÁJDITE SI VHODNÝ LIEK PODĽA PRÍZNAKOV, KTORÉ VÁS TRÁPIA</p>
            </div>
            <div className="ac">
              <div className="category">
              <a href="/nadcha/" className="box">
                  <span className="img" style={{ backgroundImage: "url(/kcfinder/uploads/files/box/Adults_SK-1.png)"}}>
                  </span>
                </a>
                <a href="/nadcha_u_deti/" className="box">
                  <span className="img" style={{ backgroundImage: "url(/kcfinder/uploads/files/box/Kids_SK-1.png)"}}>
                  </span>
                </a>
              </div>
            </div>
            <div className="hp_shortbox">
              <div className="elem">
                <a href="/radca/" className="link_box link">
                  <img src="/kcfinder/uploads/files/box/sk/Radca_SK.png" />
                </a>
              </div>
              <div className="elem">
                <a href="/upravte_liek/" className="link_box link">
                  <img src="/kcfinder/uploads/files/box/sk/Test_SK.png" />
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
      </div>
    );
  }
}

export default Page;
